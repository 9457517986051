import React, { FC } from 'react';
import styles from './spinner.module.scss';

const Spinner: FC<{ fullPage?: boolean }> = ({ fullPage }) => {
  return (
    <div className={`${styles.spinnerContainer}  ${fullPage ? styles.fullPage : ''}`}>
      <div className={styles.spinner}>
        <span className="material-symbols-outlined">cached</span>
      </div>
    </div>
  );
};

export default Spinner;
