import React from 'react';

import styles from './dropdown.module.scss';
import Select, { components } from 'react-select';

const Dropdown = (props: any) => {
  const { Option, SingleValue, IndicatorsContainer } = components;

  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.icon ? require(`./flags/${props.data.icon}.svg`) : ''}
        style={{ width: 24 }}
        alt={props.data.label}
      />
      {props.data.label}
    </Option>
  );

  const SelectedOption = (props) => (
    <SingleValue {...props}>
      <img
        src={props.data.icon ? require(`./flags/${props.data.icon}.svg`) : ''}
        style={{ width: 24 }}
        alt={props.data.label}
      />
      {props.data.label}
    </SingleValue>
  );

  const Indicator = (props) => (
    <IndicatorsContainer {...props}>
      <span className="material-symbols-outlined">expand_more</span>
    </IndicatorsContainer>
  );

  return (
    <Select
      classNames={{
        container: () => styles.dropdown,
        control: () => 'control',
        singleValue: () => 'option',
        option: () => 'option',
        valueContainer: () => 'value',
      }}
      options={props.options}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={{
        IndicatorsContainer: Indicator,
        SingleValue: SelectedOption,
        Option: IconOption,
      }}
    />
  );
};

export default Dropdown;
