import React, { FC, useCallback, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import styles from './questionnaire.module.scss';
import Spinner from '../../components/spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emailValidator from '../../helpers/email-validator';
import Header from '../../components/header';
import Button from '../../components/button';
import { DocTypes, getDocPdf, getDocumentInfo, sendEmail } from '../../api/apiService';
import NoData from '../../components/no-data';

const QuestionnairePage: FC<any> = () => {
  const [spinner, setSpinner] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [pdf, setPdf] = useState<File>();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [questionnaire, setQuestionnaire]: any[] = useState(null);
  const [emailValidity, setEmailValidity] = useState(true);
  const [email, setEmail] = useState('');

  const handleChangeEmail = (value: string) => {
    const valid = emailValidator(value) || value === '';
    setEmailValidity(valid);

    if (valid) {
      setEmail(value);
      localStorage.setItem('email', value);
    } else {
      setEmail('');
      localStorage.removeItem('email');
    }
  };

  const getQuestionnaire = useCallback(async () => {
    if (!documentId) {
      navigate('/failed');
      return;
    }

    if (questionnaire) {
      return;
    }
    setSpinner(true);
    localStorage.removeItem('signed');
    localStorage.removeItem('documentId');

    try {
      const questionnaire = await getDocumentInfo({
        docId: documentId,
        docType: DocTypes.QUESTIONNAIRE_ANSWER,
      });

      if (!questionnaire) {
        navigate('/failed');
        return;
      }

      await getPdf(documentId).catch(() => {
        sessionStorage.removeItem('documentId');
        navigate('/failed');
      });

      localStorage.setItem('documentId', documentId);

      if (questionnaire.signed) {
        localStorage.setItem('signed', questionnaire.signed);
        navigate(`/success/${DocTypes.QUESTIONNAIRE_ANSWER}`);
      } else {
        localStorage.removeItem('signed');
      }

      setQuestionnaire(questionnaire);
      setSpinner(false);
    } catch (e) {
      navigate('/failed');
      setSpinner(false);
    }
  }, [documentId, questionnaire, navigate]);

  const getPdf = useCallback(async (documentId: string) => {
    const pdf = await getDocPdf(documentId, DocTypes.QUESTIONNAIRE_ANSWER);
    setPdf(pdf);
  }, []);

  const handleClickNext = useCallback(async () => {
    if (!email || !documentId) {
      navigate(`/sign/${DocTypes.QUESTIONNAIRE_ANSWER}`);
    } else {
      const payload = {
        email: email,
        docId: documentId,
        docType: DocTypes.QUESTIONNAIRE_ANSWER,
      };
      setSpinner(true);
      await sendEmail(payload).catch(() => {});
      setSpinner(false);
      navigate(`/sign/${DocTypes.QUESTIONNAIRE_ANSWER}`);
    }
  }, [email, documentId, navigate]);

  useEffect(() => {
    const email = localStorage.getItem('email') || '';

    if (email) {
      setEmail(email);
    }

    getQuestionnaire();
  }, [documentId, questionnaire, getQuestionnaire, setEmail, navigate]);

  return (
    <>
      {spinner && <Spinner fullPage={true}></Spinner>}
      <Header></Header>
      {pdf && (
        <div className="container">
          <div className={styles.title}>{t('sign_document')}</div>
          <div className={styles.pdf}>
            {pdf && (
              <Document file={pdf} noData={<NoData />} loading={<Spinner fullPage={true} />}>
                <Page
                  pageNumber={1}
                  width={windowWidth - 40}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            )}
          </div>
        </div>
      )}
      <div className="container">
        {/* <div className={styles.emailForm}>
          <label>{t('send_copy_to_my_email')}</label>
          <div>
            <span className="material-symbols-outlined">mail</span>
            <input
              type="email"
              placeholder="Email"
              defaultValue={email}
              onChange={({ target: { value } }) => {
                handleChangeEmail(value);
              }}
            />
          </div>
        </div> */}

        <div className={styles.buttons}>
          <Button disabled={!emailValidity} onClick={handleClickNext}>
            {t('next_step')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default QuestionnairePage;
