import React from 'react';
import styles from './button.module.scss';

const Button = ({ className = 'btn', onClick, disabled, children }: any) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      disabled={disabled}
      className={`${className.split(' ').reduce((acc, className) => {
        acc = `${acc} ${styles[className]}`;
        return acc;
      }, '')}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
