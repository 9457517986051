import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import styles from './contacts.module.scss';

const Contacts = () => {
  const { boot, show } = useIntercom();
  const intercomSettings = {
    hideDefaultLauncher: true,
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  });

  const links = [
    { name: 'phone', href: 'tel:+97239444407' },
    { name: 'chat', onClick: () => show() },
    { name: 'whatsapp', href: 'http://wa.me/+972522292080' },
  ];
  return (
    <ul className={styles.contacts}>
      {links.map((linkProps) => (
        <li key={linkProps.name}>
          <a {...linkProps} className={styles[linkProps.name]} target="_blank" rel="noreferrer">
            <img
              src={require(`../../assets/${linkProps.name}.svg`)}
              width="16"
              height="16"
              alt={linkProps.name}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Contacts;
