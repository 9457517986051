import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DefaultPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('failed');
  }, [navigate]);

  return <></>;
};

export default DefaultPage;
