import React from 'react';
import { useTranslation } from 'react-i18next';
import Contacts from '../../components/contacts';
import styles from './failed.module.scss';

const FailedPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.failed}>
      <div className={styles.top}>
        <div>
          <strong className="title">{t('signature_verification_failed')}</strong>
        </div>
      </div>

      <div className={styles.main}>
        <strong className={styles.main_title}>{t('page_not_available')}</strong>
        <p>{t('please_reenter_page')}</p>

        <strong className={styles.main_sub_title}>{t('please_contact_us')}</strong>
        <Contacts></Contacts>
      </div>
    </div>
  );
};

export default FailedPage;
