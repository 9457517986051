import React from 'react';
import styles from './socials.module.scss';

const Socials = () => {
  const links = [
    { name: 'home', url: 'https://stbunion.com/' },
    { name: 'facebook', url: 'https://www.facebook.com/stb.union.financial.services/' },
    { name: 'instagram', url: 'https://www.instagram.com/stbunion/' },
  ];

  return (
    <ul className={styles.socials}>
      {links.map(({ name, url }) => (
        <li key={name} className={name}>
          <a href={url} target="_blank" rel="noreferrer">
            <img src={require(`../../assets/${name}.svg`)} width="24" height="24" alt={name} />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Socials;
