import React, { useEffect, useState } from 'react';

import CanvasDraw from 'react-canvas-draw';
import Button from '../../components/button';
import Header from '../../components/header';
import styles from './sign-page.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DocTypes, getDocPdf, uploadImage } from '../../api/apiService';
import Spinner from '../../components/spinner';
import dataURItoBlob from '../../helpers/dataURItoBlob';

const SignPage = () => {
  let saveableCanvas;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { docType } = useParams();
  const [spinner, setSpinner] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const documentId = localStorage.getItem('documentId');
  const signed = localStorage.getItem('signed');

  const uploadSign = () => {
    setTouched(true);
    if (!valid) {
      return;
    }
    setSpinner(true);
    const payload = {
      id: documentId,
      type: 'SIGN',
      file: dataURItoBlob(saveableCanvas.getDataURL('png', '', 'transparent')),
    };

    uploadImage(payload)
      .then(async () => {
        if(docType === DocTypes.QUESTIONNAIRE_ANSWER) {
          await getDocPdf(documentId, DocTypes[docType])
        }
        
        setSpinner(false);
        localStorage.setItem('signed', true);
        navigate(`/success/${docType}`);
      })
      .catch(({ message }) => {
        setSpinner(false);
        setErrorMessage(message || 'Failed to sign document');
        navigate('/failed');
      });
  };

  useEffect(() => {
    if (signed && documentId) {
      navigate(`/success/${docType}`);
      return
    }

    if (!documentId || !docType) {
      navigate('/failed');
      return
    }
  });

  return (
    <div className={styles.signPage}>
      {spinner && <Spinner fullPage={true}></Spinner>}
      <Header></Header>

      <div className="container">
        <section className="transaction-data"></section>

        <div className="sign">
          <label>{t('sign_here')}</label>

          <div className={`${styles.draw} ${!valid && touched && styles.error}`}>
            <CanvasDraw
              onChange={(data) => {
                setTouched(true);
                setValid(!!data.lines.length);
              }}
              ref={(canvasDraw) => (saveableCanvas = canvasDraw)}
              gridColor="#fff"
              canvasWidth={288}
              canvasHeight={150}
              lazyRadius={0}
              brushRadius={3}
            ></CanvasDraw>
          </div>
        </div>

        {errorMessage && <div className={styles.error}>{errorMessage}</div>}

        <div className={styles.buttons}>
          <div>
            <Button disabled={!valid && touched} className="full-width" onClick={uploadSign}>
              {t('save')}
            </Button>
          </div>
          <div className="div">
            <Button
              className="full-width cancel"
              onClick={() => {
                saveableCanvas.eraseAll();
              }}
            >
              <span className="material-symbols-outlined">close</span> {t('clear')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignPage;
